import React, {useCallback, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {EyeClose, EyeOpen} from '../../../icons/Icons';
import PasswordInput from './PasswordInput';
import emailMisspelled, {top100} from 'email-misspelled';
import {FormattedMessage} from 'react-intl';
const emailChecker = emailMisspelled({domains: top100});

const InputMask = React.lazy(()=>import('react-input-mask'))
function MaskedInput({mask, value, onChange, autoComplete, id, name, type}) {
    const [suggestions, setSuggestions] = React.useState([]);
    const onBlur = useCallback((e)=>{
        if(type === "email") {
            const suggestions = emailChecker(e.target.value);
            setSuggestions(suggestions);
        }
    },[type])
    const [t, setType] = useState(type)
    if(mask){
        return <React.Suspense fallback={<Skeleton height={36.5}/>}>
            <div className={"position-relative"}>
        <span onClick={() => setType(
            (t) => t === "password" ? "text" : "password")}
              className={"text-muted position-absolute d-inline-flex align-items-center justify-content-center px-2"}
              style={{right: 0, bottom: 0, top: 0}}>
              {
                  t === "password" ? <EyeClose/> : <EyeOpen/>
              }
        </span>
                <InputMask
                    // mask={t==="password" ? undefined : mask}
                           mask={mask}
                           placeholder={t==="password" ? mask.replaceAll("9","_") : undefined}
                           maskChar={t!=="password" ? "_" : ""}
                           onChange={onChange}
                           type={t} id={id} name={name} value={value}
                           autoComplete={autoComplete}
                           alwaysShowMask={true}
                           className={'form-control'}
                />
            </div>

        </React.Suspense>
    } else {
        return type === "password" ? <PasswordInput
                type={type} id={id} name={name} value={value}
                onChange={onChange}
                autoComplete={autoComplete}
            />
            : <React.Fragment><input type={type} id={id} name={name} value={value}
                      onChange={onChange}
                     onBlur={type === "email" ? onBlur : undefined}
                      autoComplete={autoComplete}
                      className={'form-control'}
        />
                {
                    suggestions.length > 0 ? <div className="text-danger text-end suggestions" style={{cursor: "pointer"}}>
                        <small onClick={()=> {
                            setSuggestions([])
                            onChange(
                                {target: {value: suggestions[0].corrected}})
                        }}><FormattedMessage id={"did_you_mean"} defaultMessage={`Did you mean {email} ?`}
                            values={{
                                email: suggestions[0].corrected
                            }}
                        /></small>
                    </div> : null
                }
        </React.Fragment>
    }
}

export default MaskedInput;
