import React, {useCallback, useState} from 'react';
import SignatureInput from '../form/SignatureInput';
import CountrySelector from '../../auth/components/CountrySelector';
import LazyMultipleSelector from '../multiple-selector/LazyMultipleSelector';
import {FormattedMessage, useIntl} from 'react-intl';
import Checkbox from '../form/Checkbox';
import RadioGroup from '../form/RadioGroup';
import LegalDocModal from '../../auth/signup/LegalDocModal';
import LazyPhoneInput from '../form/phone/LazyPhoneInput';
import SimpleFileUpload from '../SimpleFileUpload';
import Label from '../../auth/components/Label';
import MaskedInput from '../form/MaskedInput';
import dayjs from 'dayjs';
import SimpleDateInput from '../../SimpleDateInput';
import PasswordInput from '../form/PasswordInput';
import ScrollReadingModal from '../../ScrollReadingModal';
import mustache from 'mustache';
import LazySponsorChooser from '../form/sponsor-chooser/LazySponsorChooser';
import LazyContractInput from '../form/LazyContractInput';
import LazyW9FormInput from '../form/LazyW9FormInput';
import RenderUserPayment from '../../pages/payments/RenderUserPaymentSettings';
import useToggle from '../hooks/useToggle';
import PaymentInputPage from '../../pages/payments/PaymentInputPage';
const defaultRequirements= [
    {
        type: "length",
        value: 8,
        message: "At least 8 characters"
    },{
        type: "number",
        value: 1,
        message: "At least 1 number"
    },{
        type: "special",
        value: 1,
        message: "At least 1 special character"
    },{
        type: "uppercase",
        value: 1,
        message: "At least 1 uppercase letter"
    },{
        type: "lowercase",
        value: 1,
        message: "At least 1 lowercase letter"
    }
]
const InputMask = React.lazy(()=>import('react-input-mask'))

function RenderFormInput({
                       isRequired, label, value,
                       contract_id,
                       use_scrolling_checkbox,
                       group_id,
                        min_age, max_age,
                       w9_year,
    requirements,
                       onChange, placeholder, id, prepend, append, type, size, options, inputType, info, inputMask}) {
    let inputCmp = null;
    const intl = useIntl()
    switch (type) {
        case "password":
            inputCmp = <PasswordInput
                requirements={requirements}
                value={value} onChange={onChange}
                id={id} name={fieldNameMapper[id] || label}/>
            break;
        case "signature":
            inputCmp = <SignatureInput onChange={onChange} value={value}/>
            break;
        case 'textarea':
            inputCmp = (<textarea id={id} name={fieldNameMapper[id] || label}
                                  value={value} onInput={onChange}
                                  className={'form-control'}
            />);
            break;
        case 'country':
            inputCmp = (
                <CountrySelector value={value} onChange={onChange}/>
            );
            break;
        case "legal_document":
            inputCmp = (
                <LazyContractInput label={label} value={value} onChange={onChange}
                               contractId={contract_id}/>
            )
            break;
        case 'select': {
            if(!options) return null;
            const opts = typeof options === "string" ?  options.split('\n').
                map((item) => item.trim()).
                filter((i) => i).map((i)=>{
                    if(i.includes('|')){
                        const parts = i.split("|")
                        if(parts.length === 2) {
                            return {
                                label: parts[0],
                                value: parts[1],
                            }
                        }
                    }
                     return {
                         label:i,
                         value:i
                     }
            }) : options;
            inputCmp = (
                options &&
                <select className={'form-select'} value={value} id={id}
                        name={label} onChange={onChange}>
                    <option/>
                    {
                        opts.map(({label, value}) => <option value={value}>{intl.formatMessage({id:label || ''})}</option>)
                    }
                </select>
            );
        }
            break;
        case "multiple-select":
            const ar = typeof value === "string" && value.length > 0 ?
                value.split(",") :
                value;
            const va = ar && ar.map((val) => {
                return {value: val, label: val}
            });
            inputCmp = (
                options &&
                <LazyMultipleSelector options={options.split('\n').
                    map((item) => item.trim()).
                    filter((i) => i).
                    map((item) => {
                        return {
                            label: item,
                            value: item,
                        }
                    })} value={va}
                                      onSelected={(val) => onChange({
                                          target: {
                                              value: val ?
                                                  val.map(({value}) => value) :
                                                  undefined
                                          }
                                      })}/>
            )
            break;
        case 'radio':
            inputCmp = (options &&
                <RadioGroup options={options.split('\n').
                    map((item) => item.trim()).
                    filter((i) => i)}
                            onChange={onChange} checked={value} name={label}
                />
            );
            break;
        case 'fileupload':
            let inputProps = {};
            if (id === "profile_photo") {
                inputProps = {
                    accept: 'image/jpg, image/png, image/jpeg'
                }
            }
            inputCmp =
                <SimpleFileUpload inputProps={inputProps}
                                  value={value}
                                  onChange={(value)=>onChange({target:{value}})}/>
            break
        case 'checkbox':
            if (use_scrolling_checkbox) {
                return <div className={`form-group col-${12 / (size || 1)}`}>
                    <ScrollReadingModal value={value}
                                        label={label}
                                        onChange={onChange}
                                        isRequired={isRequired}/>
                </div>
            }
            return <div className={`form-group col-${12 / (size || 1)}`}>
                <Checkbox label={<>
                    <FormattedMessage id={label} defaultMessage={label} values={
                        {
                            terms: text => <LegalDocModal
                                to={"/program-legal/terms"}>{text}</LegalDocModal>,
                            privacy: text => <LegalDocModal
                                to={"/program-legal/privacy"}>{text}</LegalDocModal>,
                            md: text => <div className={"d-inline"}
                                             dangerouslySetInnerHTML={{
                                                 __html: mustache.render(text)
                                             }}/>,
                            html: text => <span
                                dangerouslySetInnerHTML={{__html: text}}/>
                        }
                    }/> {isRequired &&
                    <span className={"text-danger"}>*</span>}</>}
                          checked={value} value={label}
                          onChange={(e) => onChange(
                              {target: {value: e.target.checked}})}/>
            </div>;
        case "phone":
            inputCmp = <LazyPhoneInput onChange={onChange} value={value}/>
            break;
        case "sponsor":
            if (inputType === "text") {
                inputCmp = (<input className={"form-control"} type={"text"}
                                   placeholder={placeholder}
                                   value={value} onChange={onChange}
                />);
            } else {
                inputCmp =
                    <LazySponsorChooser onChange={onChange}
                                        group_id={group_id}
                                        value={value}/>
            }
            break;
        case "date":
            const val = new Date(value).toString() !== "Invalid Date" ?
                dayjs(value).format('YYYY-MM-DD') :
                value
            const max = min_age ? dayjs().subtract(min_age, 'year').format('YYYY-MM-DD') : undefined;
            const min = max_age ? dayjs().subtract(max_age, 'year').format('YYYY-MM-DD') : undefined;
            inputCmp = (<SimpleDateInput
                    min={min}
                    max={max}
                    placeholder={placeholder} id={id}
                    name={fieldNameMapper[id] || label}
                    value={val} onChange={onChange}/>);
            /*
            inputCmp = <LazyDatePicker config={{dateFormat:"d F, Y"}} onChange={(date)=> {
                if(date && date[0]){
                    onChange({target:{value: moment(date[0]).format('YYYY-MM-DD')}})
                }
            }} />

             */
            break;
        case "w9":
            inputCmp = <LazyW9FormInput w9_year={w9_year} value={value} label={label} onChange={onChange} />
            break;
        case "sponsor-field":
            return null; //TODO
            break;
        default:
            if (id === "phone") {
                inputCmp = <LazyPhoneInput   mask={inputMask} onChange={onChange} value={value}/>
            } else if(id==="payment_details_input"){
                inputCmp = <PaymentInputField onChange={onChange} value={value} />
            } else if(id === "venmo_id"){
                inputCmp = <div className="input-group mb-3">
                     <span className="input-group-text">+1</span>
                     <input name={"phone"} autoComplete={"phone"} value={value} onChange={(e)=>onChange({target:{value:e.target.value.replaceAll(/[a-zA-Z]/g,'')}})}
                            className="form-control" required pattern={"[0-9]{10}"} type={"tel"} maxLength={10} minLength={10} />
                 </div>
            }
            else {
                inputCmp = (
                    <MaskedInput type={type || inputTypeMapper[id] || 'text'}
                                 id={id} name={fieldNameMapper[id] || label}
                                 value={value} onChange={onChange}
                                 autoComplete={autofillFieldMapper[id] || 'on'}
                                 mask={inputMask}
                    />);
            }

    }
    return (<div className={`form-group col-${12 / (size || 1)}`}>
        <Label required={isRequired}><FormattedMessage id={label}
                                                       defaultMessage={label}/></Label>
        {
            (prepend || append) ?
                <div className="input-group">
                    {
                        prepend ?  <span className="input-group-text">{prepend}</span> : null
                    }
                    {
                        inputCmp
                    }
                    {
                        append ? <span className="input-group-text">{append}</span> : null
                    }
                </div> : inputCmp

        }
        {
            info && <small className="text-muted"
                           dangerouslySetInnerHTML={{__html: info}}/>
        }
    </div>);
}

function PaymentInputField({value, onChange}){
    const [visible, toggle] = useToggle(false)
    const submit = useCallback((value)=>{
        onChange({
            target:{
                value
            }
        })
    },[onChange])

    return <>
        <PaymentInputPage visible={visible}
                          onClose={toggle} payment_method={value?.payment_method || ''} payment_details_data={value?.payment_details_data || {}} onSubmit={submit}/>
        <button className="btn border text-start w-100" style={{minHeight:38.6}} onClick={toggle} type={"button"}>
            {
                value?.payment_method ?
            <RenderUserPayment payment_method={value?.payment_method} payment_details_data={value?.payment_details_data} /> :
                    <div style={{height:24.6}}/>
            }
        </button>
    </>
}

const autofillFieldMapper = {
    name: 'name',
    fname: 'given-name',
    honorific:'honorific-prefix',
    phone: 'tel',
    date_of_birth:'bday',
    gender:'sex',
    address: 'address',
    address_1: 'address-line1',
    address_2: 'address-line2',
    lname: 'family-name',
    email: 'email',
    password: 'new-password',
    state: 'address-level1',
    city: 'address-level2',
    zip: 'postal-code',
    country: 'country'
};


const inputTypeMapper = {
    email: 'email',
    phone: 'tel',
    paypal: 'email',
    'tin': 'password',
};
const fieldNameMapper = {
    name: 'name',
    fname: 'fname',
    lname: 'lname',
    phone: 'phone',
    email: 'email',
    address_1: 'address',
    address: 'address',
    city: 'city',
    state: 'state',
    country: 'country',
    zip: 'zip',
};
export default RenderFormInput;
