import React, {useEffect} from 'react';
import myemotionhoc from './myemotionhoc';
import {css} from 'emotion';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';

function getFontFamily(themeFontFamily) {
    if (themeFontFamily) {
        return `'${themeFontFamily}','Poppins', 'Helvetica Neue', 'Calibri Light', Roboto, sans-serif !important`;
    }
    return `'Poppins', 'Helvetica Neue', 'Calibri Light', Roboto, sans-serif !important`;

}

const mstyle = ({themefontfamily}) => css`
	font-family: ${getFontFamily(themefontfamily)};
`;


const OutsideContainer = myemotionhoc('div', mstyle);

function mapStateToProps(state) {
    return {
        themeFontCss: state.settings.themeFontCss,
        themeLinkColor: state.settings.themeLinkColor,
        themePrimaryColor: state.settings.themePrimaryColor,
        themeButtonTextColor: state.settings.themeButtonTextColor,
        store_name: state.settings.store_name,
        store_logo: state.settings.store_logo,
        isRtl: state.settings.isRtl,
    };
}

const ThemedMobileToolbar = connect(mapStateToProps)((props) => props.themePrimaryColor &&
    (<Helmet>
        <meta name="theme-color" content={props.themePrimaryColor}/>
        {props.themeLinkColor ? <style>
            {`
                a:not(.list-group-item, .text-secondary, .nav-link), .btn-link, a:hover:not(.list-group-item, .nav-link), .text-primary{ 
                     color: ${props.themeLinkColor} !important;
                }
                ${props.themeButtonTextColor ? `
                a.btn-primary, a.btn-primary:hover, a.btn-primary:active{
                 color: ${props.themeButtonTextColor} !important;
                }
                ` : ''
                
            }
            .btn-outline-primary{
              border-color: ${props.themeLinkColor} !important;
              color: ${props.themeLinkColor} !important;
            }
            .bg-primary{
              background-color: ${props.themePrimaryColor} !important;
            }
            // .list-group-item-action:hover, .list-group-item-action.active{
            //    background-color: ${props.themePrimaryColor} !important;
            //    color:${props.themeButtonTextColor} !important;
            // }
            .btn-outline-primary:hover{
              background-color: ${props.themeLinkColor} !important;
              color:${props.themeButtonTextColor || 'white'} !important;
            }
        `}
        </style> : null
        }
    </Helmet>));

const BrandedManifest = connect(mapStateToProps)(({store_name, themePrimaryColor, store_logo}) => {
    let myDynamicManifest = {
        name: store_name,
        short_name: store_name,
        description: store_name,
        start_url: '/?utm_source=app',
        background_color: themePrimaryColor,
        theme_color: themePrimaryColor,
        icons: [{
            src: store_logo,
            sizes: '256x256',
            type: 'image/png'
        }]
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    const selector = document.querySelector('link[rel=manifest]');
    if (selector) selector.setAttribute('href', manifestURL);
    return null;
});

export default connect(mapStateToProps)((props) => {
    return (
        <OutsideContainer className={`min-vh-100 ${props.isRtl ? 'rtl' : ''}`}>
            <ThemedMobileToolbar/>
            <InjectCssVariables themePrimaryColor={props.themePrimaryColor} />
            <BrandedManifest/>
            {
                props.themeFontCss ? <link href={props.themeFontCss} rel="stylesheet" async/> :
                    <link href={'//fonts.googleapis.com/css?family=Poppins'} rel={'stylesheet'} async/>
            }
            {props.children}
        </OutsideContainer>)
});

function InjectCssVariables({themePrimaryColor}){
    useEffect(()=>{
        if(themePrimaryColor) {
            console.log('setting property', themePrimaryColor)
            const root = document.querySelector(':root');
            // root.style.setProperty("--bs-link-color", themePrimaryColor)
            // root.style.setProperty("--bs-blue", themePrimaryColor)
            // root.style.setProperty("--theme-primary-color", `${themePrimaryColor}`)
            // root.style.setProperty("--bs-primary", `${themePrimaryColor}`)
            // root.style.setProperty("--bs-btn-bg", themePrimaryColor)
            // root.style.setProperty("--bs-btn-disabled-bg",`${themePrimaryColor}`)
        }
    },[themePrimaryColor])
   return null;
}
