import React, {useEffect, useMemo, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import FormRenderer from '../../cmp/formbuilder/FormRenderer';
import {actionMergeSetting} from '../../../redux/settings/settingsAction';
import {post} from '../../../api';
import Modal from '../../cmp/Modal';
import RenderTipaltiIframe from '../tipalti/RenderTipaltiIframe';
import {trimPostData} from '../../../tools/func';
import FormGroup from '../../cmp/form/FormGroup';

function validateUSBankAccount({
                                    bank_name,
                                   account_type,
                                   account_name,
                                   account_number,
                                   branch_code,
                               }) {
    const errors = {};
    if(!bank_name){
        errors.bank_name = 'Bank name is required';
    }
    if(!account_type){
        errors.account_type = 'Account type is required';
    }
    // Validate account name
    if (!account_name || account_name.trim() === '') {
        errors.account_name = 'Account name is required';
    }else {
        if (account_name.length < 2) {
            errors.account_name = 'Account name is too short';
        }
        if (account_name.length > 40) {
            errors.account_name = 'Account name is too long';
        }
        if (!/^[A-Za-z0-9 ]+$/.test(account_name.trim())) {
            errors.account_name = 'Account name can only contain letters, numbers, and spaces';
        }

    }


    // Validate account number (usually 4-17 digits)
    if (!account_number) {
        errors.account_number = 'Account number is required';
    } else if (!/^\d{4,17}$/.test(account_number)) {
        errors.account_number = 'Account number must be between 4-17 digits';
    }

    // Validate routing number (9 digits)
    if (!branch_code) {
        errors.branch_code = 'Routing number is required';
    } else if (!/^\d{9}$/.test(branch_code)) {
        errors.branch_code = 'Routing number must be exactly 9 digits';
    } else {
        // Check routing number using ABA checksum algorithm
        // Formula: 3(d1 + d4 + d7) + 7(d2 + d5 + d8) + (d3 + d6 + d9) % 10 should equal 0
        const digits = branch_code.split('').map(Number);
        const checksum =
            3 * (digits[0] + digits[3] + digits[6]) +
            7 * (digits[1] + digits[4] + digits[7]) +
            (digits[2] + digits[5] + digits[8]);

        if (checksum % 10 !== 0) {
            errors.branch_code = 'Routing number is invalid';
        }
    }

    return {
        isValid: Object.keys(errors).length === 0,
        errors
    };
}

function PaymentInputPage({
      updateSettings,
      visible,
      onClose,
      onSubmit,
      available_payment_methods,
      payment_method,
      payment_details_data
}){
    const [saving, setSaving] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(payment_method || '')
    const [paymentDetails, setPaymentDetails] = useState(payment_details_data)
    const [errors, setErrors] = useState({})
    useEffect(()=>{
        setPaymentMethod(payment_method || '')
        setPaymentDetails(payment_details_data)
    },[payment_method, payment_details_data])
    useEffect(()=>{
        if(available_payment_methods && available_payment_methods.length === 1){
            setPaymentMethod(available_payment_methods[0].key)
        }
    },[available_payment_methods])
    const save = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setErrors(null)
        if(paymentMethod === "bank" && country === "US"){
            setErrors(null)
            const {
                isValid,
                errors
            } = validateUSBankAccount(paymentDetails);
            if(!isValid){
                setErrors(Object.values(errors))
                return;
            }
        }
        if(paymentMethod === "paypal"){
            const email = paymentDetails['paypal_email'];
            if(!email || email.length < 5 || email.indexOf('@') === -1) {
                setErrors(['Email is invalid'])
                return;
            }
        }
        if(paymentMethod === "venmo"){
            const phone = paymentDetails['venmo_id'];
            if(!phone || phone.length < 10) {
                alert('error')
                setErrors(['Phone number is invalid'])
                return;
            }
        }
        if(onSubmit){
            if(paymentMethod) {
                onSubmit({
                    payment_method: paymentMethod,
                    payment_details_data: trimPostData(paymentDetails)
                })
            }
            onClose();
            return;
        }
        setSaving(true)
        post('/', {
            payment_method: paymentMethod,
            payment_details_data: JSON.stringify(trimPostData(paymentDetails))
        }).then((data) => {
            updateSettings(data);
            setSaving(false)
            onClose();
        }).catch((e) => {
            setSaving(false)
        });
    }

    const shouldEnable = (form)=>{
        const values = paymentDetails;
        for (let x = 0; x < form.length; x++) {
            const {id, isRequired, isEnabled} = form[x];
            if (isEnabled && isRequired){
                if(typeof values[id] === "string"){
                    if(!values[id].trim()){
                        return false;
                    }
                }else if(!values[id] || values[id].length === 0) {
                    return false;
                }
            }
        }
        return true;
    }
    const onChange = (data)=> {
        setPaymentDetails((d)=>{return {...d, ...data}})
    }
    const country = useSelector((state)=>state.settings.country)
    const isInUSA = country === "US" || country === "CA"
    const form = useMemo(()=>{
        const found =  available_payment_methods.find((item)=>item.key===paymentMethod)
        const vals =  found && found.form ? found.form : [];
        if(paymentMethod === "bank" && isInUSA){
            // do not display is the user is in USA
            return vals.filter(({id})=>id !== "swift_name")
        }else{
            return vals;
        }
    },[available_payment_methods, paymentMethod, isInUSA])

    return (
            <Modal
                size={paymentMethod === "tipalti" ? 'lg' : null}
                onCancel={onClose}
                visible={visible}
                disabled={!shouldEnable(form)}
                onSubmit={save} confirmLoading={saving}
                okText={<FormattedMessage id={"Submit"} />}>
                <FormGroup label={'Payment Mode'} required>
                    <select className={'form-control'} value={paymentMethod}
                            onChange={(e)=>setPaymentMethod(e.target.value)}>
                        <Opt value={''} id={'No payment mode set'}/>
                        {
                            available_payment_methods.map((item)=>{
                                return <Opt key={item.key} id={item.title} value={item.key} />
                            })
                        }
                    </select>
                </FormGroup>
                {
                    paymentMethod === "tipalti" ? <RenderTipaltiIframe type={"payment_details"} /> : null
                }
                <FormRenderer formFields={form}
                              onChange={onChange}
                              value={paymentDetails} />
                {
                    errors && errors.length === 1 ? <div className={'text-danger'}>
                        {errors[0]}
                    </div> : null
                }
                {
                    errors && errors.length > 1 ? <div className={'text-danger'}>
                        <ol>
                            {errors.map((item, index)=><li key={index}>{item}</li>)}
                        </ol>
                    </div> : null
                }
            </Modal>
        );
}

function mapStateToProps(state, props) {
    const {available_payment_methods} = state.settings;
     const payment_method = props.payment_method ?? state.settings.payment_method
     const payment_details_data = props.payment_details_data ?? state.settings.payment_details_data ?? {};
    return {
        available_payment_methods,
        payment_method,
        payment_details_data: typeof payment_details_data === "string" ? JSON.parse(payment_details_data) : payment_details_data,
    };
}

function Opt({id, value}) {
    return <FormattedMessage id={id || " "}>{text => <option id={createId(id)} value={value}>{text}</option>}</FormattedMessage>
}

function mapDispatchToProps(dispatch, props) {
    return {
        updateSettings: (change) => dispatch(actionMergeSetting(change))
    };
}

function createId(id){
    return id ? String(id).replace(/[^a-z0-9]/gi, '') : ''
}



export default connect(
    mapStateToProps, mapDispatchToProps
)(PaymentInputPage);
