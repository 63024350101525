import React, {useMemo} from 'react';
import {connect, useSelector} from 'react-redux';
import {FormattedNumber, useIntl} from 'react-intl';

const reducer = (state: any)=>state.settings.default_currency || "USD"
function CurrencyNumber({currency, asNumber, privacyMode, value}:{
    currency?: string,
    asNumber?: boolean
    privacyMode?: boolean,
    value: number
}) {
    const default_currency = useSelector(reducer)
    const v = useMemo(()=>{
        return value ? Number(value) : 0 || 0
    },[value])
    if(privacyMode){
        return <>***</>
    }
    if(asNumber){
        return  <FormattedNumber value={v}/>
    }
    const cur = currency || default_currency;
    if(cur=== "PTS") return <RenderPTS value={v} />
    if (v % 1 === 0){
        return (
            <>
                <FormattedNumber
                    style={"currency"}
                    currency={cur}
                    currencyDisplay={"narrowSymbol"}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    value={v}/>
            </>
        );
    }
    return (
        <>
            <FormattedNumber
                currencyDisplay={"narrowSymbol"}
                style={"currency"}
                currency={cur}
                value={v}/>
        </>
    );
}
function RenderPTS({value}:{value: number}){
    const intl = useIntl()

    if (value % 1 === 0){
        return (
            <>
                <span
                    className={"pts_currency_symbol"}>{intl.formatMessage({id: "PTS"})}&nbsp;</span>
                <FormattedNumber
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                    value={value}/>
            </>
        );
    }
    return (
        <>
            <span
                className={"pts_currency_symbol"}>
                {intl.formatMessage({id:"PTS"})}&nbsp;</span>
            <FormattedNumber
                minimumFractionDigits={0}
                maximumFractionDigits={0}
                value={value}/>
        </>
    );}

const mapStateToProps = (state, props) => ({
    currency: props.currency || (state.settings.default_currency || 'USD'),
    privacyMode: state.settings.privacyMode,
});

export default connect(mapStateToProps)(CurrencyNumber);
