import React, {useEffect, useMemo, useState} from 'react';
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import Dashboard from './DashboardLazy';
import LoginPage from './auth/LoginPage';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import {get, post, removeAccessToken} from '../api';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
    actionMergeSetting,
    buildPageSections,
} from '../redux/settings/settingsAction';
import FbPixel from './cmp/FbPixel';
import OAuthCallback from './auth/components/OAuthCallback';
import VerifyEmail from './auth/VerifyEmail';
import LandingPage from './LandingPage';
import ContactUs from './pages/ContactUs';
import LandingContact from './pages/landing/LandingContact';
import LandingSectionsIndexLazy from './pages/landing/LandingSectionsIndexLazy';
import LandingLegalIndexLazy from './pages/landing/LandingLegalIndexLazy';
import {corsUri, getCurrentQueryObject} from '../tools/func';
import {setItem, useLocalStorageState} from '../tools/storage';
import {IntlProvider} from 'react-intl';
import {Helmet} from 'react-helmet';
import InvitationPage from './auth/InvitationPage';
import CreateAccountClassic from './auth/signup/CreateAccountClassic';
import CreateAccountModern from './auth/signup/CreateAccountModern';
import CreateAccountSleek from './auth/signup/CreateAccountSleek';
import StyledAppContainer from './cmp/themed/StyledAppContainer';
import ReactGaIntegration from './cmp/ReactGAIntegration';
import {getLocale, PrimeIntl} from '../translations/localehandler';
import AccountPendingBlock from './auth/AccountPendingBlock';
import AccountBlockedBlock from './auth/AccountBlockedBlock';
import LazyFacebookChat from './cmp/chat/LazyFacebookChat';
import ShopifyLoginIndex from './pages/shopify-login/ShopifyLoginIndex';
import LazyContractPage from './pages/taxforms/contract/LazyContractPage';
import CreateAccountExtraPages from './auth/CreateAccountExtraPages';
import UnsafeJsRender from './pages/cmp/UnsafeJSRender';
import SubscribePayment from './pages/payment-plans/SubscribePayment';
import CouponPage from './pages/referral-candy-coupon-page/CouponPage';
import StripeOnCallback from './pages/payment-plans/stripe/StripeOnCallback';
import PaypalOnCallback from './pages/payment-plans/paypal/PaypalOnCallback';
import QRCodePage from './pages/qr-code-page/QRCodePage';
import ProductLinkHistoryShare from '../pages/ProductLinkHistoryShare';
import PublicForm from './PublicForm';
import usePendingRequiredFields from './cmp/hooks/usePendingRequiredInputs';
import CustomPageLazy from './pages/custom-page/CustomPageLazy';
import LazyCompleteProfileBeforeAccess
    from '../pages/LazyCompleteProfileBeforeAccess';
import ReferAFriend from './pages/ReferAFriend';
import TotpInput from './cmp/TotpInput';
import ManagedAccountsPage from './pages/ManagedAccountsPage';
import FreeGiftList from './pages/free-gift/FreeGiftList';
import PublicPartyDetailsPage from './pages/party-links/PublicPartyDetailsPage';

const MicroPortal = React.lazy(()=>import('./MicroPortal'))

function getSignupComponent(signupData) {
    const {type} = signupData;
    switch (type) {
        case 'classic':
            return <CreateAccountClassic/>;
        case 'modern':
            return <CreateAccountModern/>;
        default:
            return <CreateAccountSleek/>;
    }
}

function App(props){
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState({})
    const setItem = useLocalStorageState((state)=>state.setItem);
    const {pending_partner_dashboard_disabled, mergeSettings, account_status, password_expired,
        gtag_containerid,
        snapchat_pixel_id,
        admin_fb_pixel_id,
        aff_custom_css,
        aff_custom_js,
        fb_chat_enabled, fb_page_id,
        locale, is_app_installed, isEmbedded,
        google_analytics,
        email, signupData,
        noIndex,
        managed_accounts,
        totp_required,
        subscription,
    } = props
    function loadInitialData(){
        const obj = getCurrentQueryObject();
        const {ref, aff} = obj;
        const {utm_source, source, lang} = obj;

        if (ref || aff) {
            setItem('ref', ref || aff);
        }
        if (utm_source || source) {
            setItem('utm_source', utm_source || source);
        }
        if (lang) {
            setItem('locale', lang);
        }
        setLoading(true);
        get('/', {ref: ref || aff}).then(async (data) => {
            const {settings} = data;
            if (!settings) {
                //portal does not exist;
                setError('Affiliate portal does not exists. Kindly contact the store owner')
                return;
            }
            InjectFavicon(settings.favicon || settings.store_logo);
            try {
                settings.site_locale = settings.locale;
                const affiliateLocale = (settings.metadata ? JSON.parse(settings.metadata) : {}).locale;
                settings.locale = getLocale(affiliateLocale || settings.locale, affiliateLocale ? false : settings.localeAuto);
                settings.isRtl = ["ar","iw", "he"].indexOf(settings.locale) > -1
                settings.mLocaleMap = settings.mLocaleMap ? JSON.parse(settings.mLocaleMap) : {};
                Object.keys(settings.mLocaleMap).forEach((key) => {
                    settings.mLocaleMap[key] = corsUri(settings.mLocaleMap[key])
                })
            } catch (e) {
                console.log(e)
            }
            const messages = await PrimeIntl(settings.locale, settings.localeUri, settings.mLocaleMap || {});
            settings.locale_messages = messages[settings.locale];
            settings.page_sections = buildPageSections(settings);
            //TODO update utm parameters (referral_link_parameters) and replace any {fname} with affiliate's data
            if (settings.referral_link_params) {
                settings.referral_link_params = replaceParams(settings.referral_link_params, settings);
            }
            mergeSettings(settings);
            return messages;
        }).then((msgs) => {
            setMessages(msgs)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            switch (e.status) {
                case 403:
                case 404:
                    removeAccessToken();
                    location.reload()
                    break;
                case 422:
                    setError(<div className={"card shadow-sm"}><div className="card-body">
                        This affiliate portal is currently not attached.<br/><br/>If you are the admin, please set the same URL in the <strong>Look & Feel</strong> page -&gt; <strong>Affiliate portal URL</strong> section to activate this URL</div>
                    </div>);
                    break;
                default:
                    setError('Error loading partner portal');
            }
            setLoading(false)
        });
    }

    useEffect(()=>{
        loadInitialData();
    },[])
    const pending_input = usePendingRequiredFields();
    if (loading) {
        return (<div className={'d-flex min-vh-100 justify-content-center align-items-center'}>
            <div className="spinner-border text-secondary" role="status" style={{width: '4rem', height: '4rem'}}/>
        </div>);
    }
    if (error) {
        return (<div className={'container d-flex min-vh-100 justify-content-center align-items-center'}>
                <div style={{maxWidth:400, textAlign:'center'}}>
                    <p className={"text-danger"}>{error}</p>
                    <button className={'btn btn-link ml-2'} onClick={()=>{
                        location.reload()
                    }}>Reload page</button>
                </div>
            </div>
        );
    }
    if(is_app_installed !== undefined && !is_app_installed){
        return <div className={'d-flex min-vh-100 justify-content-center align-items-center'}>
            <div>
                <h1>Affiliate program is currently disabled</h1>
                <a href={"https://goaffpro.com"}>Goaffpro</a>
            </div>
        </div>
    }
    const l = locale && locale.startsWith("zh") ? locale.split("_").join("-") : locale;
    let Cmp = Dashboard;

    if(pending_input.length > 0 && props.incomplete_partner_dashboard_disabled){
        Cmp = LazyCompleteProfileBeforeAccess
    }
    if (account_status === "payment_pending") {
        Cmp = SubscribePayment
    }
    if (pending_partner_dashboard_disabled) {
        if (account_status === "pending") {
            Cmp = AccountPendingBlock;
        }
        if (account_status === "blocked") {
            Cmp = AccountBlockedBlock;
        }
    }
   if(managed_accounts && managed_accounts?.length >0){
        Cmp = ManagedAccountsPage;
    }
    return (
        <Routes>
            <Route element={<AppContainer
                isEmbedded={isEmbedded}
                l={l}
                locale={locale}
                fb_chat_enabled={fb_chat_enabled}
                fb_page_id={fb_page_id}
                subscription={subscription}
                snapchat_pixel_id={snapchat_pixel_id}
                admin_fb_pixel_id={admin_fb_pixel_id}
                aff_custom_css={aff_custom_css}
                aff_custom_js={aff_custom_js}
                google_analytics={google_analytics}
                gtag_containerid={gtag_containerid}
                noIndex={noIndex}
                messages={messages}
            />}>
                <Route path={"qr"} element={<QRCodePage/>} />
                <Route path={"r/:ref_code"} element={<ProductLinkHistoryShare/>} />
                <Route path={"shop/:ref_code"} element={<ProductLinkHistoryShare/>} />
                <Route path={"discount/:coupon"} element={<CouponPage/>}/>
                <Route path={'login'} element={isEmbedded && email ? <Cmp/> : <LoginPage/>}/>
                <Route path={'verifyemail/:hash'} element={<VerifyEmail/>}/>
                <Route path={'create-account/sleek'} element={<CreateAccountSleek/>}/>
                <Route path={'create-account/modern'} element={<CreateAccountModern/>}/>
                <Route path={'create-account/classic'} element={<CreateAccountClassic/>}/>
                <Route path={'signup/:handle'} element={<CreateAccountExtraPages/>}/>
                <Route path={'create-account'} element={isEmbedded && email ? <Cmp/> : getSignupComponent(signupData)}/>
                <Route path={'forgot-password'} element={<ForgotPassword/>}/>
                <Route path={'reset-password/:resetkey'} element={<ResetPassword/>}/>
                <Route path={'invite/:invitation_key'} element={<InvitationPage/>}/>
                <Route path={'auth/:type'} element={<OAuthCallback/>}/>
                <Route path={'program-details/*'} element={<LandingSectionsIndexLazy/>}/>
                <Route path={'program-legal/*'} element={<LandingLegalIndexLazy/>}/>
                <Route path={'e/:id'} element={<PublicPartyDetailsPage/>}/>

                <Route path={'contact'} element={email ? <ContactUs/> : <LandingContact/>}/>
                <Route path={"document/:contract_id"} element={<LazyContractPage/>}/>
                <Route path={'public-form/:form_id'} element={<PublicForm/>}/>
                <Route path={"micro"} element={<MicroPortal />} />
                <Route path={'shopify-login/:customer_id'} element={<ShopifyLoginIndex/>}/>
                <Route path={'wix-login/:customer_id'} element={<ShopifyLoginIndex integration={"wix"}/>}/>
                <Route path={'sso'} element={<ShopifyLoginIndex integration={"bigcommerce"} />}/>

                {
                    !email ?
                        <Route path={'pages/:page_id'}
                               element={<CustomPageLazy/>}/> : null
                }

                {/*used in mobile app to display the custom pages*/}
                <Route path={'embed-page/:page_id'} element={<CustomPageLazy isEmbedded/>}/>
                <Route path={"choose_plan/stripe_success"} element={<StripeOnCallback/>}/>
                <Route path={"choose_plan/paypal_success"} element={<PaypalOnCallback/>}/>
                <Route path={"choose_plan"} element={<SubscribePayment/>} />
                <Route path={"refer-a-friend"} element={<ReferAFriend />} />
                <Route index path={"*"} element={totp_required ? <TotpInput /> : email ? <Cmp/> : <LandingPage/>}/>
            </Route>
        </Routes>
    );
}


function LocaleSaver(){
    const locale = useSelector((state)=>state.settings?.locale)
    const plan = useSelector((state)=>state.settings?.subscription_plan)
    const site_locale = useSelector((state)=>state.settings?.site_locale)
    const savedMeta = useSelector((state)=>state.settings?.metadata)
    const dispatch = useDispatch()
    const parsed = useMemo(()=>{
        return savedMeta ? JSON.parse(savedMeta) : null;
    },[savedMeta])
    useEffect(() => {
        if((plan === "business" || plan === "enterprise") && parsed && site_locale !== locale && locale !== parsed?.locale){
            const metadata = {
                ...parsed,
                locale: locale
            }
            post('/', {
                metadata
            }).then((data) => {
               dispatch(actionMergeSetting({metadata: JSON.stringify(metadata)}))
            })
        }
    }, [plan, parsed, site_locale, locale]);
    return null;
}
function AppContainer({
      messages, isEmbedded, l,
      locale, gtag_containerid, snapchat_pixel_id, admin_fb_pixel_id,
      noIndex, aff_custom_css, aff_custom_js,
      fb_chat_enabled, fb_page_id,
      subscription,
      google_analytics,
 }){
  return <IntlProvider locale={l || 'en'}
                  fallbackOnEmptyString={false}
                  defaultRichTextElements={{
                      br:()=><br/>,
                      b:(...chunks)=><strong>{chunks}</strong>,
                  }}
                  onError={()=>true}
                 messages={messages[locale]}>
          <LocaleSaver/>
        <StyledAppContainer>
            {/*<UpdateCSSVars />*/}
            <InjectGoogleTagManager gtag_containerid={gtag_containerid}/>
            <InjectSnapPixel snapchat_pixel_id={snapchat_pixel_id} />
            <FbPixel admin_fb_pixel_id={admin_fb_pixel_id}/>
            <InjectTikTok />
            {
                isEmbedded && <InjectContentResizer/>
            }
            <InjectNoIndex noIndex={noIndex} />
            <InjectCharset locale={locale}/>
            <UpdateRTL locale={locale} />
            <InjectCustomCSS css={aff_custom_css} />
            <UnsafeJsRender script={aff_custom_js}/>
            {
                fb_chat_enabled && fb_page_id &&
                <LazyFacebookChat fb_page_id={fb_page_id}/>
            }
            {
                google_analytics &&
                <ReactGaIntegration google_analytics={google_analytics}/>
            }
            <StoreProfileCookies />
            <DispatchNavigator />
            <StoreEntryURL />
            {
                !subscription && !new URL(document.location.href).hostname.endsWith('goaffpro.com')
                    && new URL(document.location.href).hostname !== 'localhost'
                    ? <div className={"text-center text-danger shadow border-bottom p-4"}>
                    CUSTOM DOMAINS ARE NOT SUPPORTED ON THE FREE PLAN. PLEASE UPGRADE YOUR PLAN TO USE THE CUSTOM DOMAIN.<br/>
                    CONTACT <a href={"mailto:admin@goaffpro.com?subject=Upgrade my plan"}>admin@goaffpro.com</a> TO UPGRADE
                </div> : null
            }
            <Outlet />
        </StyledAppContainer>
    </IntlProvider>
}

function StoreEntryURL(){
    useEffect(() => {
        try {
            const entry_url = new URL(document.location.href);
            setItem('goaffpro_entry_url', JSON.stringify({url:entry_url}))
        }catch(e){
//            console.log(e)
        }
    }, []);
    return null;
}

const reducer = (state)=>[
    state.settings.name,
    state.settings.affiliate_id,
    state.settings.ref_code,
]
function StoreProfileCookies(){
    const [name, affiliate_id, ref_code] = useSelector(reducer)
    useEffect(() => {
        try {
            const parts = window.location.hostname.split(".")
            parts[0] = ""
            const data = affiliate_id ? JSON.stringify({
                name, affiliate_id, ref_code
            }) : ''
            document.cookie = `goaffpro_affiliate_profile=${data};path=/;max-age=${60 * 60 * 24 * 31};domain=${parts.join(".")}`
        }catch(e){
//            console.log(e)
        }
    }, [name, affiliate_id, ref_code]);
}

// for custom pages the relative URLs in html are navigated via react router listening to the events
function DispatchNavigator(){
    const navigate = useNavigate();
    useEffect(() => {
        const doNavigate = (e) => {
            console.log(typeof e.detail.url)
            navigate(e.detail.url);
        }
        window.addEventListener('goaffpro:navigate', doNavigate)
        return () => {
            window.removeEventListener('goaffpro:navigate', doNavigate);
        }
    }, []);
}

function InjectNoIndex({noIndex}){
    return noIndex ? <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet> : null
}
function InjectCustomCSS({css}){
    if(!css) return null;
    return <Helmet>
        <style type={"text/css"}>{css}</style>
    </Helmet>
}

function InjectSnapPixel({snapchat_pixel_id}){
    if(!snapchat_pixel_id) return null;
    return <Helmet>
        <script>{`
                (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
            {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                'https://sc-static.net/scevent.min.js');
                snaptr('init', '${snapchat_pixel_id}');
                snaptr('track', 'PAGE_VIEW');
            `}
        </script>
    </Helmet>
}

// function UpdateCSSVars(){
//     const themeButtonTextColor = useSelector((state)=>state.settings.themeButtonTextColor)
//     const themePrimaryColor = useSelector((state)=>state.settings.themePrimaryColor)
//
//     useEffect(() => {
//         console.log('themeButtonTextColor', themePrimaryColor)
//         if(themePrimaryColor){
//             document.body.style.setProperty('--bs-primary', themePrimaryColor);
//             document.body.style.setProperty('--bs-btn-hover-bg', themePrimaryColor);
//             document.body.style.setProperty('--bs-btn-bg', themePrimaryColor);
//         }
//         if(themeButtonTextColor){
//             document.body.style.setProperty('--bs-btn-color', themeButtonTextColor);
//         }
//     }, [themeButtonTextColor, themePrimaryColor]);
//     return null;
//
// }

function InjectTikTok(){
    const tik_tok_pixel_id = useSelector((state)=>{
        if(state.settings.affiliate_portal_config){
            return JSON.parse(state.settings.affiliate_portal_config).tiktok_pixel_id
        }
        return null;
    });
    if(!tik_tok_pixel_id){
        return null
    }
        return <>
            <Helmet>
                <script>
                    {`!function (w, d, t) {  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};  ttq.load('${tik_tok_pixel_id}');  ttq.page();}(window, document, 'ttq');`}
                </script>
            </Helmet>
        </>
}

function InjectGoogleTagManager({gtag_containerid}) {
    if(!gtag_containerid) return null;
    return <><Helmet>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtag_containerid}');`}
        </script>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtag_containerid}`}/>
        <script>
            {`
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${gtag_containerid}');
		`}
        </script>

    </Helmet>
        <noscript>
            <iframe src={`https://www.googletagmanager.com/ns.html?id=${gtag_containerid}`} height="0" width="0" style="display:none;visibility:hidden"/>
        </noscript>

    </>
}
function InjectCharset({locale}){
    return locale === "el" ? <Helmet>

            <meta charSet={"ISO-8859-7"}/>
            <meta httpEquiv={"content-type"} content={'Type=text/html; charset=ISO-8859-7'}/>
        </Helmet>
        : <Helmet>
            <meta charSet={"utf-8"}/>
        </Helmet>
}
function UpdateRTL({locale}){
    useEffect(()=>{
        document.documentElement.setAttribute('lang', locale);
        if(["ar","iw", "he"].indexOf(locale) > -1){
            document.documentElement.setAttribute('dir','rtl')
            // if(!document.getElementById('rtl-css')) {
            //     const link = document.createElement('link')
            //     link.rel = "stylesheet"
            //     link.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css";
            //     link.id = 'rtl-css'
            //     document.head.appendChild(link)
            // }
        }
    },[locale])
}

function InjectContentResizer() {
    return null;/*
    return (<Helmet script={[
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.0.4/iframeResizer.contentWindow.js',
            type: 'text/javascript'
        }
    ]}
    />);
    */
}



function InjectFavicon(store_logo) {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = store_logo;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function replaceParams(string, object) {
    let str = string;
    Object.keys(object).forEach((key) => {
        str = str.replace("{" + key + "}", object[key])
    })
    return str;
}

const mapStateToProps = (state) => ({
    email: state.settings.email,
    noIndex: state.settings.noIndex,
    aff_custom_js: state.settings.aff_custom_js,
    password_expired: state.settings.password_expired,
    locale: state.settings.locale,
    store_logo: state.settings.store_logo,
    isEmbedded: state.settings.isEmbedded,
    signupData: state.settings.signupData ? JSON.parse(state.settings.signupData) : {},
    aff_custom_css: state.settings.aff_custom_css,
    admin_fb_pixel_id: state.settings.admin_fb_pixel_id,
    pending_partner_dashboard_disabled: state.settings.pending_partner_dashboard_disabled,
    account_status: state.settings.status,
    gtag_containerid: state.settings.gtag_containerid,
    fb_chat_enabled: state.settings.fb_chat_enabled,
    fb_page_id: state.settings.fb_page_id,
    snapchat_pixel_id: state.settings.snapchat_pixel_id,
    is_app_installed:state.settings.is_app_installed,
    totp_required: state.settings.totp_required,
    incomplete_partner_dashboard_disabled:state.settings.incomplete_partner_dashboard_disabled,
    subscription: Number(state.settings.subscription || 0),
    managed_accounts: state.settings.metadata ? JSON.parse(state.settings.metadata)?.managed_accounts : null,
});

const mapDispatchToProps = (dispatch) => ({
    mergeSettings: (data) => dispatch(actionMergeSetting(data))
});



export default connect(mapStateToProps, mapDispatchToProps)(App);
