import React, {useCallback, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

function Select({value, options, onChange, hasEmpty,defaultSelectFirst}:{
    hasEmpty?: boolean,
    onChange: (str: any) => void,
    defaultSelectFirst?: boolean,
    options: Array<{ label: string, value: string, disabled?: boolean }>,
    value?: string
}) {
  const changed = useCallback((e)=>{
    if(onChange){
      onChange(e.target.value)
    }
  },[onChange])

  useEffect(() => {
    const optionExists = options && options.length > 0 ? options.find((i) => String(i.value) === String(value)) : false
    if (defaultSelectFirst) {
      if (!optionExists || !value) {
        if (options.length > 0) {
          onChange(options[0].value)
        }
      }
    }
  }, [defaultSelectFirst, onChange, options, value])
  return (
      <select className={"form-select"} value={value} onChange={changed}>
        {
          hasEmpty ? <option/> : null
        }
        {
          options.map(({label, value})=>{
            return <Opt value={value} id={label} key={value}/>
          })
        }
      </select>
  );
}

function Opt({value, id}) {
  if(!id) return <option value={value}>{id}</option>
  return <FormattedMessage id={id}>{txt => <option value={value}>{txt}</option>}</FormattedMessage>
}

export default Select;
