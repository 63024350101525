import React, {useState} from 'react';
import {post, setAccessToken} from '../../api';
import FormGroup from './form/FormGroup';
import Input from './form/Input';
import Button from './form/Button'
import AuthContainer from '../auth/components/AuthContainer';
import FormattedHelmet from './intl/FormattedHelmet';
import LogoRender from './LogoRender';
import {FormattedMessage} from 'react-intl';
import RenderError from '../auth/components/RenderError';
import StyledButton from './themed/StyledButton';
import {Link} from 'react-router-dom';
import {Md123} from 'react-icons/md';
import {useSelector} from 'react-redux';
export default function TotpInput(){
  const [token, setToken] = useState();
  const [error, setError] = useState(null);
  const [validating, setValidating] = useState(false);

  function onSubmit(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setError(null);
    setValidating(true);
    post("/../v1/user/totp/login", {
      token,
    })
    .then((data) => {
      setValidating(false);
      if (data.isValid) {
        setAccessToken(data.access_token)
        window.location.reload();
      } else {
        setError("Invalid TOTP");
      }
    }).catch((e)=>{
      setValidating(false)
      console.log(e)
    });
  }
  const [code, setCode] = useState('')
  const email = useSelector((state)=>state.settings.email);
  const name = useSelector((state)=>state.settings.name);

  return (
      <AuthContainer>
        <FormattedHelmet id={'Enter Code'}/>
        <div className={'text-center mb-4'}>
          <LogoRender/>
        </div>
        <h1 className={"text_on_bg text-center"}><FormattedMessage
            id={'Enter Code'}/></h1>
        <div className={'card shadow-sm'}>
          <div className={'card-body'}>
            {
                code && <RenderError code={code}/>
            }

            <form onSubmit={onSubmit}>
              <div className={"mb-3 text-center totp_email"}>{email}</div>
              <div className={"d-flex align-items-start gap-2 mb-3"}>
                <div style={{width: 32}}><Md123 size={32}/></div>
                <span>
              <FormattedMessage id={"totp_description"}
                                defaultMessage={"Enter the code displayed in the authenticator app on your mobile device"}/>
          </span>
              </div>
              <div className={"mb-3"}>
                <Input
                    type="number"
                    value={token}
                    placeholder={"Enter Code"}
                    onChange={setToken}
                />
              </div>
              {error && <p className="text-danger">{error}</p>}
              <StyledButton
                  title={"Submit"}
                  loading={validating}
                  onClick={onSubmit}
                  className={"btn btn-primary"}
              />
            </form>
          </div>
        </div>
        <div className={"d-none my-4 text-center text-sm d-flex justify-content-center align-items-center gap-2"}>
          <span>
            Having trouble?
          </span>
          <button className={" btn-sm btn p-0 btn-link"}>Sign in another way
          </button>
        </div>

        <div className="text-center mt-2">
          <Link to={"/logout"}>Logout</Link>
        </div>
      </AuthContainer>
  );
}
