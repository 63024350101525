import React, {useState} from 'react';
import LogoRender from "../../cmp/LogoRender";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import {get} from "../../../api";
import FormattedNumber from "../../cmp/FormattedNumber";
import CurrencyNumber from "../../cmp/CurrencyNumber";
import CopyButton from "../../cmp/CopyButton";
import SocialShareIcon from "../../cmp/SocialShareIcon";
import QrCodeModal from "../../cmp/qrcodeloader/QRCodeModal";
import {useSelector} from "react-redux";
import LoadingSkeleton from "../../cmp/LoadingSkeleton";

function PublicPartyDetailsPage(props) {
    const id = useParams().id
    const [ref_code, sub_id] = String(id).split('-')
    const {data, isLoading} = useSWR(
        ref_code && sub_id ?
        `/public-showcases?ref_code=${ref_code}&sub_id=${sub_id}` : null, get)
    const website = useSelector((state)=>state.settings?.website)
    const link =
        data?.hostUrl ||
        `https://${website}/?ref_code=${ref_code}&sub_id=${sub_id}`


    return (
        <div className={"pt-4"}>
            <div className="text-center">
                <LogoRender/>
            </div>
            <div className="d-flex justify-content-center">
                <div style={{maxWidth: 600, width: '100%'}}>
                    {
                        isLoading ? <LoadingSkeleton height={24} count={12} /> :
                            <div className="card">

                        <div className="card-body">
                            <div className="text-center">
                                <span>{data?.affiliate?.name}<br/>{data?.affiliate?.email}</span>
                            </div>
                            <div className="text-center">
                                <div className="fs-2">X</div>
                            </div>
                            <div className="text-center">
                                <span>{data?.hostName}<br/>{data?.hostEmail}</span>
                            </div>
                            <div className="text-center mt-2 mb-2 d-flex align-items-center justify-content-center">
                                <CopyButton text={link}
                                            className={"btn mr-2 btn-sm btn-outline-primary"}
                                            title={"Copy Link"} />
                                <QRButton link={link}/>
                            </div>
                        </div>
                        <ul className="list-group list-group-flush">
                            <StatsItem label={"Total Sales"} value={isLoading ? '-' : <FormattedNumber value={data?.orders?.num_orders} />}/>
                            <StatsItem label={"Total Volume"} value={isLoading ? '-' : <CurrencyNumber value={data?.orders?.subtotal} />}/>
                        </ul>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}
function QRButton({link}){
    const [showQrModal, toggleQRModal] = useState(false)
    return <>
        <SocialShareIcon disabled={!link} onClick={()=>toggleQRModal(true)} alt={"QR Code"}
                         src={"/images/qr-code.svg"}/>
        <QrCodeModal value={link} onClose={()=>toggleQRModal(false)} visible={showQrModal}/>
    </>
}

function StatsItem({label, value}) {
    return (
        <li className="list-group-item space-between">
            <span>{label}</span>
            <span>{value}</span>
        </li>
    )
}

export default PublicPartyDetailsPage;
