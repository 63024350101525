import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionMergeSetting} from "../redux/settings/settingsAction";

interface UserInfo {
    "latitude": string,
    "postalCode":string;
    "timezone":string;
    "longitude": string;
    "city":string;
    "country":string;
    "region":string;
}


export default function useUserInfo(trigger = true): Promise<UserInfo> | undefined {
    const dispatch = useDispatch();
    //@ts-ignore
    const userInfo = useSelector((state)=>state.settings?.userInfo)
    useEffect(()=>{
        if(!userInfo && trigger) {
//            fetch('https://user-info.goaffpro.workers.dev/')
                fetch('https://user-info.goaff.pro')
                .then((data) => data.json())
                .then((res) => {
                    dispatch(actionMergeSetting({
                        userInfo: res
                    }))
                })
        }
    },[trigger, userInfo])
    return userInfo;
}
