import React, {useCallback} from 'react';
import Skeleton from 'react-loading-skeleton';
import emailMisspelled, {top100} from 'email-misspelled';

const MaskedInput = React.lazy(()=>import('react-input-mask'))
const emailChecker = emailMisspelled({domains: top100});


function Input(props) {
  const onChange = props.onChange
  const [suggestions, setSuggestions] = React.useState([]);
  const onBlur = useCallback((e)=>{
    if(props.onBlur){
      props.onBlur(e)
    }
    if(props.type === "email") {
      const suggestions = emailChecker(e.target.value);
      setSuggestions(suggestions);
    }
  },[props.onBlur, props.type])
  const changed = useCallback((e)=>{
    setSuggestions([]);
    if(onChange){
      onChange(e.target.value)
    }
  },[onChange])

  if(props.mask){
    return <React.Suspense fallback={<Skeleton height={36.5}/>}>
      <MaskedInput
          className={"form-control"}  {...props}
          mask={props.mask} maskChar={"_"}
          onChange={changed}/>
    </React.Suspense>
  }
  return (
      <Wrapper prefix={props.prefix} suffix={props.suffix}>
        <input className={"form-control"} {...props} onBlur={onBlur} onChange={changed}/>
        {
          suggestions.length > 0 ? <div className="suggestions">
            <ul>
              {
                suggestions.map((suggestion, index)=><li key={index}>{suggestion}</li>)
              }
            </ul>
          </div> : null
        }
      </Wrapper>
  );
}

function Wrapper({prefix, children, suffix}){
  if(prefix || suffix){
    return <div className="input-group">
      {
          prefix && <span className="input-group-text">{prefix}</span>

      }
      {children}
      {
          suffix && <span className="input-group-text">{suffix}</span>
      }
    </div>
  }
  return children
}


export default Input;
